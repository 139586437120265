@import "./themes/generated/variables.base.scss";

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {

  // background-color: #ffffff;
  // opacity: 1;
  // background-image: radial-gradient(rgba(69, 78, 247, 0.18) 0.4px, #ffffff 0.4px);
  // background-size: 8px 8px;

  // background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

  background: linear-gradient(90deg, #f5fff7 0%, #f3f7ff 100%);

  display: flex;
  height: 100%;
  width: 100%;

  .content {
    line-height: 1.5;
    flex-grow: 1;

    h2 {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .container {
    height: 100%;
    flex-direction: column;
    display: flex;
  }

  .layout-body {
    flex: 1;
    min-height: 0;
  }

  .content-block {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
  }
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px)
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content {
  height: 100%;

  &>.dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-x: auto ;
  }
}

$side-panel-min-width: 60px;

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}


.user-info {
  line-height: 20px;
}

.dx-calendar-cell.dx-calendar-other-view {
  color: inherit;
}

.dx-calendar-cell.dx-calendar-empty-cell.dx-calendar-other-view.dx-calendar-other-month {
  color: rgba(0, 0, 0, .24)
}

.side-navigation-menu {
  // background-color: #f4f4f4 !important;
  // background-color: #f3fcf5;
  background: linear-gradient(90deg, #f5fff7 0%, #f3f7ff 100%);
}


.dx-texteditor.dx-editor-filled::after {
  border: none;
}

.dx-texteditor.dx-state-focused::before {
  border: none;
}

.colSeperator {
  border-right-color: darkgray !important;
  border-right-width: 2px !important;
  border-right-style: solid !important;
}

.dx-datagrid-total-footer .colSeperator {
  border: none !important;
}


.dx-swatch-additional .dx-datagrid .dx-row-lines>td {
  border: 1px solid #e0e0e0;
}

.header-toolbar {
  background: transparent;
}







.divToolbar{
  // background: rgb(245,238,241);
  // background: radial-gradient(circle, rgba(245,238,241,1) 0%, rgba(214,223,235,1) 100%);
  padding: 10px;
  border-radius: 5px;
  // border:1px solid rgb(255, 255, 255);
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btnToolbar{
  margin-right: 20px;
  font-size: 12px;
  padding: 2px;
  max-height: 20px;
}


.my-scrollable-grid{
overflow-x: auto;
overflow-y: auto;
min-width: 1500px;
}

.dx-portfoy{
min-width: 100%;
}

// .dx-swatch-additional .dx-scrollable-native.dx-scrollable-both > .dx-scrollable-wrapper > .dx-scrollable-container {
//   overflow-x: hidden;
// }

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .divToolbar {
    flex-direction: column;
  }
}







.dx-button.dx-button-default{
  background-color: #3424ed
}

.dx-button.dx-button-default.dx-state-hover{
  background-color: #3424eddd;
}

.dx-button.dx-button-default.dx-state-active{
  background-color: #3424edff;
}

.dx-button.dx-button-default.dx-state-focused{
  background-color: #3424edff;
}





.dx-button-mode-outlined.dx-button-default{
  background-color: white;
  color:#3424ed;
  border-color: #3424ed;
}

.dx-button-mode-outlined.dx-button-default.dx-state-active{
  background-color: #3424ed33;
}

.dx-button-mode-outlined.dx-button-default.dx-state-hover{
  background-color: rgba(156, 39, 176, .08);
}


.dx-button-mode-outlined.dx-button-default.dx-state-focused{
  background-color: #3424ed33;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected > .dx-treeview-item *{
  color: #3424ed !important
}

.dx-theme-material-typography a {
  color: #3424ed;
}

.create-account-form .login-link {
  color: #3424ed;
}

.create-account-form .policy-info a {
  color: #3424ed;
}






// .dx-swatch-additional .dx-editor-cell .dx-texteditor .dx-texteditor-input {
//   padding: 5px
// }

// .dx-swatch-additional .dx-datagrid .dx-row>td {
//   padding: 5px
// }

// .dx-swatch-additional .dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row>td {
//   padding: 5px;
// }

// .dx-swatch-additional .dx-editor-cell .dx-texteditor .dx-texteditor-input{
//   height: 22px !important;
//   min-height: 22px !important;
// }

// .dx-swatch-additional .dx-list-item-content{
//   padding: 5px;
// }