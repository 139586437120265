@import "../../themes/generated/variables.base.scss";

.landing-page .dx-button{
    min-width: 200px;
    margin-right: 50px;
    margin-bottom: 50px;
    height: 50px;
}

.landing-page .dx-button-text{
    font-size: 20px;
    text-transform: none;
}

.landing-page #logo{
    font-size: 50px;
}

.landing-page #imgSS{
    opacity:0.7 ;
}

.landing-page #imgSS:hover{
    opacity:1 !important;
}

.landing-page .credit{
    margin-top: 20px;
    text-align: center;
}

.landing-page .credit img{
    opacity:0.7 ;
}