.home {

    .dx-pivotgrid *:not(:empty) {
        font-size: 16px !important;
    }

    .dx-pivotgrid .dx-bottom-border {
        border: none !important
    }

    .dx-pivotgrid .dx-grandtotal {
        background-color: transparent !important;
    }

    tr:has(.dx-area-description-cell) {
        display: none;
    }

    .dx-pivotgrid .dx-grandtotal.dx-last-cell,
    .dx-pivotgrid .dx-row-total.dx-last-cell {
        padding-left: 16px
    }

    .divDurum {
        display: flex;
        flex-direction: row;
    }

    .divDurumChart,
    .divDurumPivot {
        margin: 0 20px 0 5px;
    }


    /* Responsive layout - makes a one column layout instead of a two-column layout */
    @media (max-width: 800px) {
        .divDurum {
            flex-direction: column;
        }

        .divDurumChart,
        .divDurumPivot {
            margin: 5px 0 20px 0;
        }

    }
}