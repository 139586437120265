@import "../../themes/generated/variables.base.scss";

.change-password-form {
  .policy-info {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    font-size: 14px;
    font-style: normal;

    a {
      color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }


  .dx-button {
    min-width: 200px;
    margin-right: 50px;
    margin-bottom: 50px;
    height: 50px;
  }

  .dx-button-text {
    font-size: 20px;
    text-transform: none;
  }
}

