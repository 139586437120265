@import "../../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }


  .dx-button {
    min-width: 200px;
    margin-right: 50px;
    margin-bottom: 50px;
    height: 50px;
  }

  .dx-button-text {
    font-size: 20px;
    text-transform: none;
  }

}