@import "../../themes/generated/variables.base.scss";

.reset-password-form {
  .submit-button {
    margin-top: 10px;
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }

  .dx-button {
    min-width: 200px;
    margin-right: 50px;
    margin-bottom: 50px;
    height: 50px;
  }

  .dx-button-text {
    font-size: 20px;
    text-transform: none;
  }
}
