@import "../../themes/generated/variables.base.scss";

.footer {
  display: block;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  // border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  padding-top: 10px;
  padding-bottom: 14px;
}

.footerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .footerWrapper {
    flex-direction: column;
  }
  .footerWrapper div:last-child {
    margin-top: 20px;
  }
}