#logo {
  color: #00008b;
  font-weight: bold;
  display: block !important;
  cursor: pointer;
  font-size: 32px;
  padding: 0px 0px 0px 2px;
  line-height: 22px;
  // margin:10px;
  line-height:40px;
  text-align: center;
}